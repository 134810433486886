/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap'); */

.hero-question-one {
  padding: 8px 8px;
  border-radius: 6px;
  text-align: center;
  background: var(--primary-10, #ebeefa);
  color: var(--fortuna-blue, #444444);
  text-align: center;
  /* Other/Tagline */
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.084px;
  margin-bottom: 20px;
}
.hero-header {
  color: var(--neutral-100, #0e0f11);
  text-align: center;
  font-family: "Inter";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 128.571% */
  letter-spacing: -0.588px;
    margin-bottom: 20px;
}
.hero-caption {
  color: var(--neutral-80, #636b75);
  text-align: center;
  /* Body text/M - Regular */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: -0.176px;
    margin-bottom: 4rem;
}
