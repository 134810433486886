@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  
}

/* fonts.css */

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter.ttf') format('ttf');
  /* Add more font file formats as needed */
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
