/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap'); */
* {
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

.information {
border-radius: 20px;
background: rgba(85, 112, 241, 0.04);
backdrop-filter: blur(50px);
flex-shrink: 0;
} 

.info-title {
color: var(--fortuna-blue, #1347F5);
/* Heading/XS */
font-family: "Inter";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
letter-spacing: -0.252px;
}

.info-heading {
    color: var(--neutral-100, #0E0F11);
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.378px;
    margin-top: 8px;
    align-self: stretch;

}

.info-text {
    color: var(--neutral-80, #636B75);
    /* Body text/M - Regular */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: -0.176px;
    margin-top: 8px;
}