/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap'); */
.swal2-success { overflow:hidden !important }

.swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #1347F5 !important;
  color: #fff;
  font-size: 1em;
}
.info-wrapper {
  display: inline-flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 6px;
  background: var(--primary-10, #ffffff);
}
.info-wrapper-header {
  color: var(--fortuna-blue, #1347f5);
  text-align: center;
  /* Other/Tagline */
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.084px;
}

.container{
  background: var(--primary-10, #bfbfc0);
}

