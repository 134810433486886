/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap'); */

.process-information {
  /* border-radius: 20px; */
  /* background: rgba(85, 112, 241, 0.04); */
  /* backdrop-filter: blur(50px); */
}

.process-icon-wrapper {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--primary-10, #f2f6ff);
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-title {
  color: var(--fortuna-blue, #1347f5);
  /* Heading/XS */
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.252px;
}

.info-heading {
  /* Heading/XS */
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.252px;
  align-self: stretch;
}

.info-text {
  width: 100%;
  color: var(--neutral-80, #636b75);
  /* Body text/M - Regular */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: -0.176px;
  margin-top: 8px;
}

@media only screen and (min-width: 800px) {
  .info-text {
    /* width: 30ch; */
    /* width: 60ch; */
  }
}
