/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap'); */

/* header {
  width: "100%";
  height: 600px;
  flex-shrink: 0;
 
  
   background: url("../../assets/landing.png")  50% /   cover no-repeat; 
  mix-blend-mode: soft-light;
 
} */
header {

   background-color: rgba(25, 22, 96, 1);

   /*background: url("../../assets/landing.png") #1347f5 50% / cover no-repeat; */
   /* background: url("../../assets/landing.png")  50% /   cover no-repeat; 
  mix-blend-mode: soft-light; */
  /*opacity: 0.7;*/
  /* margin-bottom: 6rem; */
}
.banner-header {
  color: var(--material-theme-white, #fff);
  text-align: center;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 133.333% */
  letter-spacing: -0.792px;
  margin-bottom: 24px;
  text-decoration: none;
}

.banner-caption {
  color: #fff;
  text-align: center;

  /* Body text/M - Regular */
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
  letter-spacing: -0.176px;
  /* align-self: stretch; */
  margin-bottom: 24px;
  width: 70%;
}

.banner-play {
  color: var(--neutral-10, #fff);

  /* Button text/M */
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  letter-spacing: -0.176px;
}

@media only screen and (max-width: 799px) {

  header {
    width: "100%";
    height: "100%";
    flex-shrink: 0;
     /*background-color: var(--fortuna-blue, #1347f5); */
     /*background: url("../../assets/landing.png") #1347f5 50% / cover no-repeat; */
     background-color: var(rgba(25, 22, 96, 1));
    /* margin-bottom: 6rem; */
  }
  
  .banner-header {
    color: var(--material-theme-white, #fff);
    text-align: center;

    /* Heading/XL */
    font-family: "Inter";
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 118.182% */
    letter-spacing: -0.968px;
    margin-bottom: 24px;
    align-self: stretch;
    height: 800px;
    /* overflow-y: hidden; */
  }
}

.test {
  background: linear-gradient(180deg, #1347f5 50%, #fff 50%);
  /* background: url("../../assets/landing.png")  50% /   cover no-repeat; */
  mix-blend-mode: soft-light;
  /* opacity: 0.7; */
}

a {
  text-decoration: none;
}