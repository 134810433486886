@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300&family=Inter:wght@300;500&display=swap");

.modal-title {
  color: #000;
  text-align: center;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal-caption {
  color: var(--fortuna-gray, #45464e);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
  margin-top: 28px;
}

.modal-request {
  color: var(--primary-60, #2f54eb);
  /* Button text/M */
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 175% */
  letter-spacing: -0.176px;
}

.modal-input,
.modal-select {
  border-radius: 10px;
  background: var(--secondary-grey-300, #f4f7fe);
  height: 46px;
  text-indent: 1rem;
  width: 100%;
}
.modal-input:focus {
  outline: none;
  border: none;
}

.modal-select {
  /* appearance: none; */
  border: none;
  outline: none;
}
select option {
  padding: 10px;
  background-color: #fff;
  color: #333;
  font-family: Arial, sans-serif; /* Set desired font */
  transition: background-color 0.2s ease; /* Add a transition effect */
}

/* Style when the dropdown is open */
select:focus {
  border: 1px solid #3490dc;
}

/* Hover effect for options */
select option:hover {
  background-color: #3490dc;
  color: #fff;
}
